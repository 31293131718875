import * as $ from 'jquery';
import 'owl.carousel.es6'

const runOwlCarousel = function (){
    if ($(".carousel-section").length > 0) {
        var window_width = $(window).width();
        var fixOwl = function(){
            var $stage = $('.owl-stage'),
                stageW = $stage.width(),
                $el = $('.owl-item'),
                elW = 0;
            $el.each(function() {
                elW += $(this).width()+ +($(this).css("margin-right").slice(0, -2))
            });
            if ( elW > stageW ) {
                $stage.width( elW );
            }
        }

        $('.carousel-section').each(function() {
            var navMobile = window_width < 991 ? '.grid-x' : '';
            var navContainer = $(this).find(navMobile + ' .arrows .cell');
            var currentCarousel = $(this).find('.owl-carousel');

            if (currentCarousel.find('.slide').length == 1 && window_width < 768) {
                currentCarousel.find('.slide').css('width','calc(100vw - 40px)');
            }

            $(this).find('.owl-carousel').owlCarousel({
                items: 1,
                lazyLoad: true,
                lazyLoadEager: 1,
                loop: $(this).find('.slide').length > 1 ? true : false,
                dots: false,
                nav: $(this).find('.slide').length > 1 ? true : false,
                navContainer: $(this).find('.slide').length > 1 ? navContainer : '',
                autoWidth: true,
                onInitialized: fixOwl,
                onRefreshed: fixOwl,
                responsive: {
                    0: { margin: 15, items: 1, },
                    769: { margin: 30, },
                    1024: { margin: 40, },
                    1280: { margin: 60 }
                }
            });

            var firstPictureSize = currentCarousel.find('.owl-item').width();

            if ($(this).hasClass('align-carousel-left') && window_width > 991) {
                $(this).find('.slider-wrapper .owl-carousel .owl-stage').css("margin-left", "calc(100% - " + firstPictureSize + "px)");
            }
        });
    } else {
        setTimeout(() => {
            runOwlCarousel();
        }, 500);
    }
}
export default runOwlCarousel;
